import AuthWrapper from 'components/AuthWrapper';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { signUp, togglePasswordVisibility } from 'redux-modules/auth/actions';
import * as selectors from 'redux-modules/auth/selectors';
import HpaStoreContext from '../../../contexts/HpaStoreContext';
import errorMessages from '../../../helpers/errorMessages';
import getInitialValuesFromQuery from '../../../helpers/getInitialValuesFromQuery';
import VerificationCode from '../../NewRegistrationFlow/Verification';
import ClaimAccount from '../ClaimAccount';
import EmailValidation from '../PreSignUp/EmailValidation';
import Registration from '../Registration';
import Success from '../Success';

const toSignIn = {
  pathname: '/login',
  search: window.location.search,
};

const Register = withRouter(
  ({
    isRequestPending,
    isPasswordVisible,
    PasswordComplexityFeature,
    togglePasswordVisibilityConnect,
    user,
    history,
    location,
  }) => {
    const [registrationStep, setRegistrationStep] = useState(1);
    const {username} = useContext(HpaStoreContext);
    const context = useContext(HpaStoreContext);
    let initialValues = getInitialValuesFromQuery(location.search);
    initialValues = {
      ...initialValues,
      email: initialValues.email || initialValues.username || username,
      ...location.state,
    };
    
    const getNewSignupFlowDetail = () => {
        return context && Array.isArray(context.customBranding) && context.customBranding.find((detail) => detail.name === 'IsNewSignupWorkflow' && detail.text === 'NewSignupWorkflow');
     };

    useEffect(() => {
      const path = location.pathname;
      switch (path) {
        case '/account/claimaccount':
          setRegistrationStep(0);
          break;
        case '/account/signup':
          setRegistrationStep(1);
          break;
        case '/account/confirm':
          setRegistrationStep(2);
          break;
        case '/account/registration':
          setRegistrationStep(3);
          break;
        case '/account/success':
          setRegistrationStep(4);
          break;
        default:
          setRegistrationStep(1);
          break;
      }
    }, [location.pathname]);

    if (user && user.isRegistered) {
      return <Redirect push to="/" />;
    }
    
    if (!(getNewSignupFlowDetail() && getNewSignupFlowDetail().value === "1")) {
        return <Redirect push to={{
          pathname: "/signup",
          search: (new URL(window.location.href)).search,
        }} />;
      }

    const titles = {
      0: 'Found An Existing Account',
      1: 'Enter your email to get started',
      2: 'Verify Your Email',
      3: 'Enter your name and password',
    };

    const registrationStepComponentMap = {
      0: ClaimAccount,
      1: EmailValidation,
      2: VerificationCode,
      3: Registration,
      4: Success,
    };

    const navigate = (step, data) => {
      const page = {
        0: '/account/claimaccount',
        1: '/account/signup',
        2: '/account/confirm',
        3: '/account/registration',
        4: '/account/success',
      };
      history.push({
        pathname: page[step],
        state: data,
        search: window.location.search,
      });
    };
    const RegisterStep = registrationStepComponentMap[registrationStep];
    return (
      <AuthWrapper classList={['SignUp']}>
        {registrationStep < 4 && (
          <div>Registration: Step {Math.max(1, registrationStep)} of 3</div>
        )}
        <h2 className="AuthWrapper__register__title">
          {titles[registrationStep]}
        </h2>
        <RegisterStep
          navigate={navigate}
          disabled={isRequestPending}
          toSignIn={toSignIn}
          registrationFields={location.state}
          initialValues={initialValues}
          PasswordComplexityFeature={PasswordComplexityFeature}
        />
      </AuthWrapper>
    );
  },
);

export const registrationSteps = {
  CLAIMACCOUNT: 0,
  EMAILVALIDATION: 1,
  VERIFICATIONCODE: 2,
  REGISTRATION: 3,
  SUCCESS: 4,
};

export const error = {
  errorFound: false,
  errorMessage: '',
  button: {
    text: '',
    link: '',
  },
};

export const setGenericError = (setError) => {
  setError((_error) => ({
    errorFound: true,
    errorMessage: errorMessages.processing.message,
  }));
};

Register.propTypes = {
  isRequestPending: PropTypes.bool,
  isPasswordVisible: PropTypes.bool,
  PasswordComplexityFeature: PropTypes.bool,
  togglePasswordVisibilityConnect: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isRegistered: PropTypes.bool,
  }),
};

Register.defaultProps = {
  isPasswordVisible: false,
  PasswordComplexityFeature: false,
  isRequestPending: false,
  user: undefined,
};

const mapStateToProps = (state) => ({
  isRequestPending: selectors.isRequestPending(state),
  isPasswordVisible: selectors.isPasswordVisible(state),
  user: selectors.getUser(state),
});

const mapDispatchToProps = {
  signUpConnect: signUp,
  togglePasswordVisibilityConnect: togglePasswordVisibility,
};

const ConnectedRegister = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Register);

export default ConnectedRegister;
export { Register as PureRegister };
