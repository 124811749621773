import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row, Button, Spinner } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PasswordField from '../../../components/fields/PasswordField';
import TermsField from 'components/fields/TermsField';
import TextField from 'components/fields/TextField';
import { registrationSteps } from "../PreSignUp";
import identityClient from '../../../clients/identityClient';
import ErrorAlert from '../PreSignUp/ErrorAlert';
import errorMessages from '../../../helpers/errorMessages';
import { error as err } from '../PreSignUp';

const Registration = ({
    isRequestPending,
    PasswordComplexityFeature,
    navigate,
    handleSubmit,
    registrationFields,
    invalid,
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(err);
    const isLoadingOrPending = isLoading || isRequestPending;
    const isPasswordInvalid = PasswordComplexityFeature && !isPasswordValid;
    const isContinueDisabled = isLoadingOrPending || isPasswordInvalid || invalid;

    const onPasswordToggle = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const onContinue = async (values) => {
        setIsLoading(true);
        const { password, firstName, lastName } = values; 
        try {
            await identityClient.registerUser(firstName, lastName, registrationFields.email, password);
            navigate(registrationSteps.SUCCESS);
        } catch (e) {
            setError((_error) => ({
                errorFound: true,
                errorMessage: e.response?.data?.status === 400 ? e.response?.data?.detail : errorMessages?.processing?.message,
            }));
        } finally {
            setIsLoading(false);
        }  
    };

    return (
        <Form className="mt-4" onSubmit={handleSubmit(onContinue)}>
            {error.errorFound && (
                <ErrorAlert error={error} />
            )}
            <Row>
                <Col sm="6">
                    <TextField
                        label="First Name"
                        name="firstName"
                        disabled={isRequestPending}
                        required
                    />
                </Col>
                <Col sm="6">
                    <TextField
                        label="Last Name"
                        name="lastName"
                        disabled={isRequestPending}
                        required
                    />
                </Col>
            </Row>

            <PasswordField
                displayPassword={isPasswordVisible}
                onToggle={onPasswordToggle}
                disabled={isRequestPending}
                featureToggle={!PasswordComplexityFeature}
                showTooltip={true}
                setIsPasswordValid={setIsPasswordValid}
            />

            <PasswordField
                confirm
                displayPassword={isPasswordVisible}
                onToggle={onPasswordToggle}
                disabled={isRequestPending}
                featureToggle={!PasswordComplexityFeature}
                showTooltip={false}
            />

            <TermsField disabled={isRequestPending} />

            <Row className="justify-content-center">
                
                <Button id="registration" color="primary" className="btn btn-md" type="submit" style={{ width: '25%' }} disabled={isContinueDisabled}>
                        {isLoading ? <Spinner size="sm" /> : 'Continue'}
                    </Button>
            </Row>
        </Form>
    );
};

Registration.propTypes = {
    isRequestPending: PropTypes.bool,
    PasswordComplexityFeature: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
};

Registration.defaultProps = {
    isRequestPending: false,
    PasswordComplexityFeature: false,
};

export default reduxForm({
    form: 'newRegistrationForm',
})(Registration);