import React from 'react';
import { Link } from 'react-router-dom';
import AuthWrapper from 'components/AuthWrapper';


const RecoverAccount = (props) => {


    const maskedEmail = props.location.state.maskEmail.email;
    const maskedNumber = props.location.state.maskEmail.phoneNumber;
    const loginLink = {
        pathname: '/login',
        search: window.location.search,
    };
    return (
        <AuthWrapper>
            <div className='p-3'>
                <p className='mb-2 recoverPtage_1'><strong>Primary Account Details:</strong></p>
                {maskedEmail &&
                    <p className='my-3 ml-3 recoverPtage_2'>
                        Your primary email address is: <strong>{maskedEmail}</strong>
                        {/* Your primary email address is: <strong>Email</strong> */}
                    </p>
                }
                {maskedNumber &&
                    <p className='mb-3 ml-3 recoverPtage_3'>
                        Your primary mobile number is: <strong>{maskedNumber}</strong>
                        {/* Your primary mobile number is: <strong>Mobile number</strong> */}
                    </p>
                }
                <p className='mb-5 recoverPtage_4'>
                    Please return to sign in page and login with your primary username.
                </p>

                <Link to={loginLink} className='btn btn-primary btn-block mt-5'>Return to Sign in</Link>
            </div>
        </AuthWrapper>
    )
}

export default RecoverAccount
