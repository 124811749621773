import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  signUp,
  togglePasswordVisibility,
  verifyRegistration,
} from 'redux-modules/auth/actions';
import * as selectors from 'redux-modules/auth/selectors';
import { Alert } from 'reactstrap';
import AuthWrapper from 'components/AuthWrapper';
import getInitialValuesFromQuery from 'helpers/getInitialValuesFromQuery';
import locationShape from 'shapes/locationShape';
import userShape from 'shapes/userShape';
import redirect from 'helpers/redirect';
import RegisterForm from './RegisterForm';
import HpaStoreContext from '../../contexts/HpaStoreContext';

class Register extends Component {
  onSubmit = (values) => {
    values.email = values.email?.trim();
    const { signUpConnect, redirectUri,handleUserdetails } = this.props;
    const combinedData = Object.assign({}, values, { redirectUri });
    handleUserdetails(values);
    signUpConnect(combinedData);
  };

  getInitialValues = () => {
    const { location } = this.props;
    const { search } = location;
    return getInitialValuesFromQuery(search, {
      email: this.props.email,
      given_name: this.props.firstName,
      family_name: this.props.lastName,
      phone: '',
      password: '',
      confirmPassword: '',
      contact_method: 'email',
      terms: false,
    });
  };
    getCustomBrandingDetail = (name) => {
        return Array.isArray(this.context.customBranding) && this.context.customBranding.find(
            (detail) => detail.name === name && detail.text === 'customBranding'
        );
    };
    getNewSignupFlowDetail = () => {
     return this.context && Array.isArray(this.context.customBranding) && this.context.customBranding.find((detail) => detail.name === 'IsNewSignupWorkflow' && detail.text === 'NewSignupWorkflow');
  };
  render() {
    const {
      isPasswordVisible,
      togglePasswordVisibilityConnect,
      isRequestPending,
      errorMessage,
      location,
      user,
      completedRegisterStepOne,
      PasswordComplexityFeature
      } = this.props;

    if (user && user.isRegistered) {
      return <Redirect push to="/" />;
      }
    if (this.getNewSignupFlowDetail() && this.getNewSignupFlowDetail().value === "1") {
        return <Redirect push to={{
          pathname: "/account/signup",
          search: (new URL(window.location.href)).search,
        }} />;
      }
    var updatedURL = new URL(window.location.href);
    updatedURL.searchParams.delete('username');
    updatedURL.searchParams.delete('firstname');
    updatedURL.searchParams.delete('lastname');
    updatedURL.searchParams.delete('email');
    const redirectName = redirect.parseRedirectName(location);
      const customBrandingDisplayName = this.getCustomBrandingDetail('displayName');
      const RegistrationInstructionText = this.getCustomBrandingDetail('registrationInstructionText');
    return (
        <AuthWrapper title="Create your hStream ID" redirectName={customBrandingDisplayName ? `${customBrandingDisplayName.value}` : redirectName}>
        {errorMessage && !errorMessage.includes('Task timed out after') && (
          <Alert color="danger">{errorMessage}</Alert>
        )}
        {errorMessage && errorMessage.includes('Task timed out after') && (
          <Alert>
            Check your email or phone for a link to verify your hStream ID.
          </Alert>
        )}
            {RegistrationInstructionText && (
        <div>
                    <Alert color="info">{RegistrationInstructionText.value}</Alert>
       </div>
        )}
        {!completedRegisterStepOne && (
          <section>
            <RegisterForm
              onSubmit={this.onSubmit}
              isRequestPending={isRequestPending}
              onPasswordToggle={togglePasswordVisibilityConnect}
              isPasswordVisible={isPasswordVisible}
              PasswordComplexityFeature={PasswordComplexityFeature}
              initialValues={this.getInitialValues()}
            />
          </section>
        )}
        {completedRegisterStepOne && (
          <Redirect
            to={{
              pathname: '/confirm',
              state: {
                username: user.username,
                email: user.verificationFlow.address,
              },
              search: updatedURL.search,
            }}
          />
        )}
      </AuthWrapper>
    );
  }
}

Register.propTypes = {
  isRequestPending: PropTypes.bool.isRequired,
  signUpConnect: PropTypes.func.isRequired,
  isPasswordVisible: PropTypes.bool,
  PasswordComplexityFeature: PropTypes.bool,
  completedRegisterStepOne: PropTypes.bool,
  togglePasswordVisibilityConnect: PropTypes.func.isRequired,
  verifyRegistrationConnect: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  location: locationShape.isRequired,
  redirectUri: PropTypes.string,
  user: userShape,
};

Register.defaultProps = {
  isPasswordVisible: false,
  PasswordComplexityFeature: false,
  completedRegisterStepOne: false,
  errorMessage: undefined,
  user: undefined,
  redirectUri: undefined,
};

const mapStateToProps = (state) => ({
  isRequestPending: selectors.isRequestPending(state),
  isPasswordVisible: selectors.isPasswordVisible(state),
  completedRegisterStepOne: state.auth.completedRegisterStepOne,
  errorMessage: selectors.getErrorMessage(state),
  user: selectors.getUser(state),
});

const mapDispatchToProps = {
  signUpConnect: signUp,
  togglePasswordVisibilityConnect: togglePasswordVisibility,
  verifyRegistrationConnect: verifyRegistration,
};

Register.contextType = HpaStoreContext;

export default connect(mapStateToProps, mapDispatchToProps)(Register);

export { Register as PureRegister };
