import React, { useState } from 'react';
import { Form, Button, Spinner, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import identityClient from 'clients/identityClient';
import { maskEmail } from '../../../utils/maskingUtils';
import VerificationField from 'components/fields/VerificationField';
import ResendCodeSelection from '../../../components/ResendCodeSelection';
import ErrorAlert from '../PreSignUp/ErrorAlert';
import { error as err } from '../PreSignUp';
import errorMessages from '../../../helpers/errorMessages';
import { registrationSteps } from "../PreSignUp";

const VerificationCode = ({ navigate, handleSubmit, registrationFields }) => {
    const [error, setError] = useState(err);
    const [resent, setResent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { email, hStreamId } = registrationFields;
    const codeSentMsg = `A verification code has been ${resent ? 're' : ''}sent to ${maskEmail(email)}, if the email exists.`;
    const onContinue = async (verification) => {
        setIsLoading(true);
        try {
            await identityClient.verifyRegistrationOtp(hStreamId, email, verification);
            navigate(registrationSteps.REGISTRATION, registrationFields);
        } catch (e) {
            setError((_error) => ({
                errorFound: true,
                errorMessage: e.status === 400 ? errorMessages?.invalidCode?.message : errorMessages?.genericError?.message,
            }));
            setIsLoading(false); 
        }
    }

    const onResend = () => {
        setIsLoading(true);
        identityClient.sendRegistrationOTP(registrationFields.email, registrationFields.hStreamId)
            .then(() => {
                setError((_error) => ({
                    errorFound: false,
                }));
                setResent(true);
                setIsLoading(false);
            })
            .catch((e) => {
                setError((_error) => ({
                    errorFound: true,
                    errorMessage: errorMessages.resendCode.message,
                }));
                setIsLoading(false);
            });
    };

    return (
        <>
            <Form className="AuthWrapper__register"
                onSubmit={handleSubmit((values) => {
                    const verification = values.verification;
                    onContinue(verification);
                })}
            >
                {error.errorFound ? (
                    <ErrorAlert error={error} />
                ) : (
                    <Alert>{codeSentMsg}</Alert>
                )}

                <VerificationField
                    label="Verification Code"
                    name="verification"
                    required
                />
                <Button id="verification" disabled={isLoading} color="primary" block>
                    {isLoading ? <Spinner size="sm" /> : 'Continue'}
                </Button>
            </Form>
            <div className="AuthWrapper__content-message">
                <hr />
                <ResendCodeSelection resendCode={onResend} email={registrationFields.email} />
                <div>
                    <b>Already verified?</b>{' '}
                    <Link to={{ pathname: '/login', search: window.location.search }}>Sign In</Link>
                </div>
            </div>
        </>

    );
}

export default reduxForm({
    form: 'verificationCode',
})(VerificationCode);

export { VerificationCode as PureVerificationCodeForm };
