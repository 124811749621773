import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';


const ErrorAlert = ({ error }) => {
    const getClassNames = () => {
        return 'd-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-items-center mb-2 justify-content-between';
    };
    return (
        <Alert
            color="danger"
            className={getClassNames()}>
            <span>{error && error.errorMessage}</span>
            {error.button &&
                <Link
                    to={{
                        pathname: error.button.link,
                        search: window.location.search,
                    }}
                    className="btn btn-sm btn-danger justify-content-sm-center my-auto"
                    style={{ whiteSpace: 'nowrap' }}
                    id={error.button.id}
                >
                    {error.button.text}
                </Link>
            }
        </Alert>
    );
};

export default ErrorAlert;