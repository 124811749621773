const errorMessages = {
    processing: {
        message: 'An error occurred while processing your request.',
    },
    generic: {
        message: 'We encountered an issue. You may try to sign in again, or if this error persists, please contact your administrator.',
    },
    resendCode: {
        message: 'An error occurred while resending your code. Please try again.',
    },
    invalidLogin: {
        message: 'The password you have entered is invalid.',
    },
    expiredPassword: {
        message: 'Your password has expired.',
    },
    forceResetPassword: {
        message: 'We are implementing a password reset policy as part of our continuous improvements that ensure data security.',
    },
    expiredVerificationLinkError: {
        message: 'Your verification link has expired or been previously used to reset your password.',
    },
    incorrectPassword: {
        message: 'Incorrect password. Please check your current password and try again.',
    },
    accountLocked: {
        message: 'Account locked due to multiple attempts. Try again in a few minutes.',
    },
    noActiveSession: {
        message: 'You have been logged out due to inactivity.',
    },
    emailExists: {
        message: 'An hStream ID with this email already exists.',
    },
    multipleProvisioned: {
        message: "There was a problem associated with this email. Please contact Support.",
    },
    invalidCode: {
        message: "Invalid verification code provided, please try again.",
    },
};

export default errorMessages;
