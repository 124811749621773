import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Form, FormGroup, Row, Col, Spinner } from 'reactstrap';
import * as selectors from 'redux-modules/auth/selectors';
import { togglePasswordVisibility, checkMobilePrompt} from 'redux-modules/auth/actions';
import AuthWrapper from 'components/AuthWrapper';
import PasswordField from 'components/fields/PasswordField';
import AuthWrapperFooter from 'components/AuthWrapperFooter';
import HpaStoreContext from 'contexts/HpaStoreContext';
import identityClient from '../../clients/identityClient';
import FormatErrorMessage from '../FormatMessage/FormatErrorMessage';
import errorMessages from 'helpers/errorMessages';

const LoginWithPassword = ({ handleSubmit, primaryUserName, setDisplayPasswordPage, newSignupFlow }) => {
    const isPasswordVisible = useSelector(selectors.isPasswordVisible);
    const context = useContext(HpaStoreContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const [errorStatus, setErrorStatus] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const locationHook = useLocation();
    const params = locationHook.search;

    const toRegister = {
        pathname: newSignupFlow && newSignupFlow.value === "1" ? '/account/signup' : '/signup',
        search: params,
    };
    const toResetPassword = {
        pathname: '/forgotPassword',
        search: params,
    };
    const toLogin = {
        pathname: '/login',
        search: params,
    };

    const handleTogglePasswordVisibility = () => {
        dispatch(togglePasswordVisibility());
    };

    const displayUserName = decodeURIComponent(primaryUserName);

    const handleFormSubmit = async (values) => {
        const { loginPassword } = values;
        setIsSubmitting(true);
        try {
            const queryString = params ? `${params}` : '';
            await identityClient.oauthLogin(displayUserName, loginPassword, queryString).then(async (response) => {
                if (response.emails.length > 0 || response.phoneNumbers.length > 0) {
                    history.push({
                        pathname: '/verifyIdentity',
                        search: queryString,
                        state: { emails: response.emails, phoneNumbers: response.phoneNumbers, verifyInfo: response, queryString },
                    });
            } else {
                await dispatch(checkMobilePrompt(queryString));
            }});
            setErrorStatus(false);
            setErrorMessage(undefined);
        } catch (err) {
    if (err.response && err.response.data && err.response.data.status === 401 ) {
        setErrorMessage(errorMessages.invalidLogin);
        setErrorStatus(true);
      } 
      else if (err.response && err.response.data && err.response.data.status === 400 && err.response.data.title === "Password Expired" ) {
        setErrorMessage(errorMessages.expiredPassword);
        setErrorStatus(true);
      } 
      
      else if (err.response && err.response.data && err.response.data.status === 400 && err.response.data.title === "Forcing Password" ) {
        setErrorMessage(errorMessages.forceResetPassword);
        setErrorStatus(true);
      } 
      
      else if (err.response && err.response.data && err.response.data.status === 400 && err.response.data.title === "Account Locked" ) {
        setErrorMessage(errorMessages.accountLocked);
        setErrorStatus(true);
      }
      else{
        setErrorMessage(errorMessages.invalidLogin);
        setErrorStatus(true);
      }
        } finally {
            setIsSubmitting(false);
        }
    };
    const shouldSuppressRegister = context && context.customBranding && context.customBranding.suppressRegister && context.customBranding.suppressRegister.toLowerCase() === "true";
    return (
        <>
            <AuthWrapper
                footer={<AuthWrapperFooter />}
            >
          {errorStatus && <FormatErrorMessage error={errorMessage.message} recoverErrorCode={errorMessage.message} recoverEmail={primaryUserName} />}

                <p style={{ fontSize: '17px' }}>Logging in as <b>{displayUserName}</b></p>
                <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <PasswordField
                        displayPassword={isPasswordVisible}
                        onToggle={handleTogglePasswordVisibility}
                        disabled={isSubmitting}
                        showTooltip={false}
                        skipValidation
                        name="loginPassword"
                    />
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ margin: '-.5em 0 1rem' }}
                    >
                        <Link to={toResetPassword} className="text-muted text-sm">
                            Forgot your password?
                        </Link>
                    </div>
                    <FormGroup>
                        <Button
                            className="btn"
                            block
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                        >
                            {isSubmitting ? <Spinner size="sm" color="light" /> : 'Continue'}
                        </Button>
                    </FormGroup>
                </Form>
                    <>
                        <hr />
                        <Row>
                        {(shouldSuppressRegister === undefined || shouldSuppressRegister === null || shouldSuppressRegister === false)  && (
                            <Col>
                                <section className="d-flex align-items-center">
                                    <span style={{ marginRight: '.5em' }}>Need to sign up?</span>
                                    <Link to={toRegister}>Register Now</Link>
                                </section>
                            </Col>
                           
                        )}
                            <Col>
                                <section className="d-flex align-items-center float-right">
                                    <Link to={toLogin} onClick={() => setDisplayPasswordPage(false)} id="loginWithDifferentUser">Login with different user</Link>
                                </section>
                            </Col>
                        </Row>
                        </>
              
            </AuthWrapper>
        </>
    );
};

LoginWithPassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    ssoIntegrationOptions: PropTypes.object.isRequired,
    primaryUserName: PropTypes.string.isRequired,
    setDisplayPasswordPage: PropTypes.func.isRequired,
};

const ConnectedPasswordForm = reduxForm({
    form: 'password',
})(LoginWithPassword);

export default ConnectedPasswordForm;
export { LoginWithPassword as PurePasswordForm };
