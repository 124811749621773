import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Success = () => {
    const toLogin = {
        pathname: '/login',
        search: window.location.search,
    };
    const params = new URLSearchParams(window.location.search);
    const responseType = params && params.get('response_type');

    return (
        <>
            <div>
                <h2 className="AuthWrapper__content-headline">
                    Success! You have registered.
                </h2>
                <div className="AuthWrapper__content-message">
                    <p>You can sign in on the next screen.</p>
                </div>
            </div>
            <div className="AuthWrapper__content-action">
                {responseType === 'code' ? (
                    <Link to={{
                        pathname: '/account/authorize',
                        search: window.location.search,
                    }}>
                        <Button
                            id="registerSuccess"
                            color="primary"
                            block
                        >
                            Continue
                        </Button>
                    </Link>
                ) : (
                    <Link to={toLogin}>
                        <Button color="primary" block id="registerSuccess">
                            Continue
                        </Button>
                    </Link>
                )}
            </div>
        </>
    );
};

export default Success;
