import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, Spinner } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { registrationSteps } from "../PreSignUp";
import identityClient from 'clients/identityClient';

const ClaimAccount = ({ navigate }) => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { email, hStreamId, firstName, lastName, latestPersonId } = location.state || {};

    const onCancelRegister = () => {
        navigate(registrationSteps.EMAILVALIDATION);
    };
    const onClickContinue = async () => {
        setIsLoading(true);
        try {
            await identityClient.sendRegistrationOTP(email, hStreamId);
            navigate(registrationSteps.VERIFICATIONCODE, { email, hStreamId, firstName, lastName, latestPersonId });
        } catch (error) {
            console.error("Error sending OTP:", error);
        }
        setIsLoading(false);
    };

    return (
        <div>
            <div className="AuthWrapper__register">
                Good News! We found an account pre-created with your email : <p className="font-weight-bold" id="precreatedEmail">{email}</p>
            </div>
            <div className="p-3">
                <Row className="bg-light px-2 py-3 d-flex align-items-center">
                    <Col sm="9">
                        <div>I want to claim this account</div>
                    </Col>
                    <Col sm="3">
                        <Button id="claimAccount" color="primary" block className="btn btn-sm float-right" onClick={onClickContinue} disabled={isLoading}>
                            {isLoading ? <Spinner size="sm" /> : 'Continue'}</Button>
                    </Col>
                </Row>
            </div>
            <div className="px-3">
                <Row className="bg-light px-2 py-3 d-flex align-items-center">
                    <Col sm="7">
                        <div>I do not want to claim this account</div>
                    </Col>
                    <Col sm="5">
                        <Button id="backToRegister" color="primary" block className="btn btn-sm float-right" onClick={onCancelRegister} >Back to Registration</Button>
                    </Col>
                </Row>
            </div>
        </div>  
    );
};

ClaimAccount.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default ClaimAccount;
