import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, Form, FormGroup } from 'reactstrap';
import PhoneNumberField from 'components/fields/PhoneNumberField';
import { Tooltip } from 'reactstrap';

const PhonePromptForm = ({ handleSubmit, onSubmit, isRequestPending }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const TooltipStyles = {
    container: {
      maxWidth: '350px',
    },
    div: {
      textAlign: 'left',
      padding: '5px',
      margin: 0,
      maxWidth: '330px'
    },
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <PhoneNumberField
        label="Mobile Number"
        name="phone"
        id="mobilePromptPhone"
        disabled={isRequestPending}
        required
      />
      <PhoneNumberField
        label="Confirm Mobile Number"
        name="confirm-phone"
        disabled={isRequestPending}
        required
        confirm
      />
      <FormGroup>
        <Button color="primary" block disabled={isRequestPending}>
          Send Verification Code
        </Button>
      </FormGroup>
      <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target="mobilePromptPhone"
              toggle={() => setTooltipOpen(!tooltipOpen)}
            delay={{ show: 50, hide: 50 }}
            className="bg-white"
            trigger="focus"
            id="mobilePromptPhoneTooltip"
            style={TooltipStyles.container}
        >
            <div style={TooltipStyles.div}>
            Only U.S. and Canada mobile numbers are allowed. Skip to the Application if you don't have one.
            </div>
        </Tooltip>
    </Form>
  );
};

PhonePromptForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPasswordToggle: PropTypes.func.isRequired,
  isRequestPending: PropTypes.bool,
  isPasswordVisible: PropTypes.bool,
};

PhonePromptForm.defaultProps = {
  isRequestPending: false,
  isPasswordVisible: false,
};

const ConnectedPhonePromptForm = reduxForm({
  form: 'phone-prompt',
})(PhonePromptForm);

export default ConnectedPhonePromptForm;
export { PhonePromptForm as PurePhonePromptForm };
