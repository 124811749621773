import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Form, Col, Row, Button, Spinner } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import EmailField from 'components/fields/EmailField';
import identityClient from 'clients/identityClient';
import ErrorAlert from '../ErrorAlert';
import { error as err, registrationSteps, setGenericError } from '../';
import errorMessages from '../../../../helpers/errorMessages';
import HpaStoreContext from '../../../../contexts/HpaStoreContext';

const EmailValidation = ({
    handleSubmit,
    toSignIn,
    navigate,
    touch,
}) => {
    const context = useContext(HpaStoreContext);
    const [error, setError] = useState(err);
    const locationHook = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const params = locationHook.search;
    const onContinue = async (email) => {
        context.setData({ username: email });
        setIsLoading(true);
        try {
            const queryString = params ? `${params}` : '';
            const validationResponse = await identityClient.preRegistrationValidation(email, queryString);
            const { isProvisioned, hStreamId, firstName, lastName, latestPersonId } = validationResponse?.data;
            const isNewUser = !isProvisioned;
            if (isNewUser) {
                const otpResponse = await identityClient.sendRegistrationOTP(email, hStreamId);
                navigate(registrationSteps.VERIFICATIONCODE, { email: email, hStreamId: hStreamId });
            }
            if (isProvisioned) {
                navigate(registrationSteps.CLAIMACCOUNT,
                    {
                        email: email,
                        hStreamId: hStreamId,
                        firstName: firstName || '',
                        lastName: lastName || '',
                        latestPersonId: latestPersonId,
                    });
            }
        } catch (e) {
            if (e.status === 409) {
                setError((_error) => ({
                    ..._error,
                    errorFound: true,
                    errorMessage: errorMessages.emailExists.message,
                    button: {
                        text: "Sign In",
                        link: "/login",
                        id: "registerLoginButton"
                    },
                }));
                if (e.response && e.response.data && e.response.data.detail === "Multiple provisioned records or unconfirmed with UserProfiles are available") {
                    setError((_error) => ({
                        ..._error,
                        errorMessage: errorMessages.multipleProvisioned.message,
                        button: {
                            text: "Need Help?",
                            link: "/needHelp",
                            id: "needHelp"
                        },
                    }));
                }
            }
            else {
                setGenericError(setError);
            }
            setIsLoading(false);
        }
    };
    return (
        <>
            <Form
                className="AuthWrapper__register"
                onSubmit={handleSubmit((values) => {
                    const email = values?.email;
                    if (email) {
                        onContinue(email);
                    } else {
                        touch('email');
                        setError((_error) => ({
                            ..._error,
                            errorFound: false,
                        }));
                    }
                })}
            >
                {error.errorFound && <ErrorAlert error={error} />}
                <EmailField
                    label="Email"
                    name="email"
                    disabled={isLoading}
                />
                <div>
                    <Row>
                        <Col sm="4">
                            <Button color="primary" block disabled={isLoading} type="submit" id="validateEmail">
                                {isLoading ? <Spinner size="sm" /> : 'Continue'}
                            </Button>
                        </Col>
                        <Col sm="8" className="d-flex align-items-center">
                            <div className="AuthWrapper__register-actions">
                                Already have an hStream ID?
                                <Link
                                    to={toSignIn}
                                    onClick={() => context.setData({ username: '' })}>
                                    Sign In
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Form>

        </>
    );
};

EmailValidation.propTypes = {
    toSignIn: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'emailValidation',
})(EmailValidation);

export { EmailValidation as EmailValidationForm }; 
